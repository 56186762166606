import React, { FunctionComponent } from "react";
import { Disqus } from "gatsby-plugin-disqus";
import styled from "styled-components";
import { Container } from "@nehalist/gatsby-theme-nehalem/src/components/common";

const DisqusWrapper = styled(Container)`
  border-bottom: 1px #e5eff5 solid;
  padding-top: 40px;
`;

/**
 * Placeholder which is attached under every post. Can be shadowed to
 * quickly integrate comments (like commento, Disqus, ...).
 */
// @ts-ignore
const Comments: FunctionComponent<{ id: string }> = ({ id }) => (
  <DisqusWrapper>
    <Disqus config={{ identifier: id, title: id }} />
  </DisqusWrapper>
);

export default Comments;
